<template>
  <div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
      <div class="text-center">
        <not-authorized-component />
      </div>
      <div class="w-100 text-center">
        <h2 class="my-1">
          <strong>
            {{ $t('pages.outOfPlace') }}
          </strong>
        </h2>
        <p class="mb-2">
          {{ $t('generic.dontHavePermission') }}
        </p>
        <p class="text-center mt-2">
          <b-link :to="loginRoute()">
            <i class="icon-0-icons-dark-step-back" /> {{ $t('generic.backToHome') }}
          </b-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink } from 'bootstrap-vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import NotAuthorizedComponent from '@/views/error/ImageNotAuthorized.vue'

export default {
  components: {
    BLink,
    NotAuthorizedComponent,
  },
  methods: {
    loginRoute() {
      const user = JSON.parse(localStorage.getItem('userData'))
      return getHomeRouteForLoggedInUser(user ? user.role : null)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
